<template>

    <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
        <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <i class="text-green-600 text-2xl icon-file-excel-o"></i>
        </div>
        <h2 class="text-lg text-gray-900 font-medium title-font mb-2">{{title}}</h2>
        <p class="leading-relaxed text-base">{{text}}</p>
        
        <slot/>
        <!-- <button @click="click" class="mx-auto mt-6 text-white bg-indigo-500 border-0 py-1 px-4 focus:outline-none hover:bg-indigo-600 rounded text-md w-full content-center">Yüklə</button> -->
    
        </div>
    </div>
    
</template>
<script>

export default{
    props:{
        title: String,
        text: String,

    }
}

</script>