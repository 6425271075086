<template>
    <div class="flex flex-col border border-gray-200">
        <slot></slot>
    </div>
</template>

<script>
/*
 * Import Components
 * */

export default {
    name: "Card",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
