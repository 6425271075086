import { render, staticRenderFns } from "./ModalHead.vue?vue&type=template&id=33765f32&scoped=true"
import script from "./ModalHead.vue?vue&type=script&lang=js"
export * from "./ModalHead.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33765f32",
  null
  
)

export default component.exports