import CustomStore from "devextreme/data/custom_store";
import storeOrdersService from "../../services/store-order.service";

const StoreOrdersStore = {
    namespaced: true,

    /* State */
    state: {
        storeOrder: {},
        storeOrders: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.storeOrder = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.storeOrders = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get storeOrders
         * */
        getStoreOrders({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return storeOrdersService.get(null, {datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },

        updateStoreOrder({commit}, payload) {
            return storeOrdersService.post('update', payload);
        },
        /*
         * Get storeOrder
         * */
        getStoreOrder({commit}, payload) {
            return storeOrdersService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },
        /*
         * Get Select storeOrders
         * */
        getSelectStoreOrders({commit}, payload = {}) {
            return storeOrdersService.get(null, payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },
        /*
         * Set storeOrder
         * */
        setStoreOrder({commit}, payload) {
            if (payload.id)
                return storeOrdersService.put(payload.id, payload);
            return storeOrdersService.post(null, payload);
        },
        /*
         * Reset Order
         * */
        resetStoreOrder({commit}) {
            commit('SET_DATA', {});
        },
        /*
         * Action Order
         * */
        actionStoreOrder({commit}, payload) {
            return storeOrdersService.post('action', payload);
        },
        /*
         * Delete Order
         * */
        deleteStoreOrder({commit}, payload) {
            return storeOrdersService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default StoreOrdersStore;
