import { render, staticRenderFns } from "./AppLayoutDefault.vue?vue&type=template&id=5b511c46&scoped=true"
import script from "./AppLayoutDefault.vue?vue&type=script&lang=js"
export * from "./AppLayoutDefault.vue?vue&type=script&lang=js"
import style0 from "./AppLayoutDefault.vue?vue&type=style&index=0&id=5b511c46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b511c46",
  null
  
)

export default component.exports