import CustomStore from "devextreme/data/custom_store";
import wishlistService from "../../services/wishlist.service";

const WishlistStore = {
    namespaced: true,

    /* State */
    state: {
        wishlist: [],
    },

    /* Mutation */
    mutations: {
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.wishlist = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get Wishlist
         * */
        getWishlist({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return wishlistService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            // console.log(data);
            commit('SET_LIST', data);
        },
        
    },

    /* Getters */
    getters: {}
}

export default WishlistStore;
