<template>
    <div class="dropdown">
        <slot/>
    </div>
</template>

<script>
/*
 * Import Components
 * */

export default {
    name: "Dropdown",
    data() {
        return {}
    }
}
</script>

<style scoped>
    @import "./Dropdown.css";
</style>
