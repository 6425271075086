<template>
    <ckeditor
        :editor="editor"
        v-model="element"
        :config="editorConfig"
        class="form-element"
    />
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FormMixin from "../../../mixins/FormMixin";

export default {
    name: "FormHtmlEditor",
    mixins: [FormMixin],
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            element: this.value,
            editorConfig: this.getEditorConfig()
        };
    },
    props: {
        height: {
            default: 500
        },
        type: {
            default: 'full'
        },
        value: {
            type: String,
            default: ''
        }
    },
    watch: {
        value(val) {
            this.element = val || '';
        }
    },
    methods: {
        getEditorConfig() {
            const vm = this;
            let toolbar = [];

            if (vm.type === 'minimum') {
                toolbar = ['bold', 'italic'];
            } else if (vm.type === 'simple') {
                toolbar = [
                    'undo', 'redo', '|', 'formatselect', '|', 'bold', 'italic', 'fontColor', 'fontBackgroundColor', '|',
                    'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
                    'bulletedList', 'numberedList', '|', 'blockQuote', 'insertTable', 'imageUpload', '|', 'mediaEmbed', 'undo', 'redo', 'fullscreen'
                ];
            } else if (vm.type === 'mail') {
                toolbar = [
                    'undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
                    'bulletedList', 'numberedList', '|', 'link', 'unlink', 'blockQuote'
                ];
            } else {
                toolbar = [
                    'undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
                    'bulletedList', 'numberedList', '|', 'link', 'unlink', 'blockQuote', 'insertTable', 'mediaEmbed', 'imageUpload', '|',
                    'fontColor', 'fontBackgroundColor', '|', 'highlight', '|', 'removeFormat', 'sourceEditing'
                ];
            }

            return {
                toolbar: toolbar,
                language: 'en',
                ckfinder: {
                    uploadUrl: process.env.VUE_APP_API_URL + '/file-management/photo/editor-upload'
                }
            };
        }
    }
};
</script>

    
