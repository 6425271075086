import CustomStore from "devextreme/data/custom_store";
import trackingService from "../../services/trackingUrls.service";

const TrackingUrlsStore = {
    namespaced: true,

    /* State */
    state: {
        track: {},
        tracks: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.track = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.tracks = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get Vacancies
         * */
        getTracks({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return trackingService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Blog
         * */
        getTrack({ commit }, payload) {
            return trackingService.get(payload)
                .then(r => {
                    commit('SET_DATA', r.data.response);
                })
        },
        
        /*
         * Set Track
         * */
        setTrack({ commit }, payload) { // burdakı commitlər işlədilmir hərdəfə buna görə xəta alıram neyniyim?
            if (payload.id)
                return trackingService.put(payload.id, payload);
            return trackingService.post(null, payload);
        },
        /*
         * Action Track
         * */
        actionTrack({ commit }, payload) {
            return trackingService.post('action', payload);
        },
        /*
         * Delete Track
         * */
        deleteTrack({ commit }, payload) {
            return trackingService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default TrackingUrlsStore;