import CustomStore from "devextreme/data/custom_store";
import OtherIncomeService from "../../services/other-income.service";

const OtherIncomeStore = {
    namespaced: true,

    /* State */
    state: {
        incomeitem: {},
        incomeitems: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.incomeitem = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.incomeitems = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get Incomes
         * */
        getIncomes({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return OtherIncomeService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Income
         * */
        getIncome({ commit }, payload) {
            return OtherIncomeService.get(payload)
                .then(r => {
                    commit('SET_DATA', r.data.response);
                })
        },
        /*
         * Get Select Incomes
         * */
        getSelectIncomes({ commit }, payload = {}) {
            return OtherIncomeService.get(null, payload)
                .then(r => {
                    commit('SET_LIST', r.data.response);
                })
        },
        /*
         * Set Income
         * */
        setIncome({ commit }, payload) { // burdakı commitlər işlədilmir hərdəfə buna görə xəta alıram neyniyim?
            if (payload.id)
                return OtherIncomeService.put(payload.id, payload);
            return OtherIncomeService.post(null, payload);
        },
        /*
         * Action Income
         * */
        actionIncome({ commit }, payload) {
            return OtherIncomeService.post('action', payload);
        },
        /*
         * Delete Incomes
         * */
        deleteIncome({ commit }, payload) {
            return OtherIncomeService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default OtherIncomeStore;