import CustomStore from "devextreme/data/custom_store";
import storesService from "../../services/portal-stores.service";

const StoresStore = {
    namespaced: true,

    /* State */
    state: {
        store: {},
        stores: [],
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.store = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.stores = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get StoreLanguages
         * */
        getStores({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return storesService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get StoreLanguage
         * */
        getStore({ commit }, payload) {
            return storesService.get(payload)
                .then(r => {
                    commit('SET_DATA', r.data.response);
                })
        },
        /*
         * Get Select StoreLanguages
         * */
        getSelectStores({ commit }, payload = {}) {
            return storesService.get(null, payload)
                .then(r => {
                    commit('SET_LIST', r.data.response);
                })
        },
        /*
         * Set StoreLanguage
         * */
        setStore({ commit }, payload) {
            if (payload.id)
                return storesService.put(payload.id, payload);
            return storesService.post(null, payload);
        },
        /*
         * Delete StoreLanguage
         * */
        deleteStore({ commit }, payload) {
            return storesService.delete(payload);
        }
    },


    /* Getters */
    getters: {}
}

export default StoresStore;