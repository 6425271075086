import { render, staticRenderFns } from "./AppHeaderUserDropdown.vue?vue&type=template&id=1ccf0fc8&scoped=true"
import script from "./AppHeaderUserDropdown.vue?vue&type=script&lang=js"
export * from "./AppHeaderUserDropdown.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ccf0fc8",
  null
  
)

export default component.exports