import CustomStore from "devextreme/data/custom_store";
import OtherIncomeCategoriesService from "../../services/other-income-categories.service";

const OtherIncomeCategoriesStore = {
    namespaced: true,

    /* State */
    state: {
        category: {},
        categories: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.category = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.categories = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get Products
         * */
        getIncomeCategories({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return OtherIncomeCategoriesService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Blog
         * */
        getIncomeCategorie({ commit }, payload) {
            return OtherIncomeCategoriesService.get(payload)
                .then(r => {
                    commit('SET_DATA', r.data.response);
                })
        },
        /*
         * Get Select Blogs
         * */
        getSelectIncomeCategories({ commit }, payload = {}) {
            return OtherIncomeCategoriesService.get(null, payload)
                .then(r => {
                    commit('SET_LIST', r.data.response);
                })
        },
        /*
         * Set Blog
         * */
        setIncomeCategorie({ commit }, payload) { // burdakı commitlər işlədilmir hərdəfə buna görə xəta alıram neyniyim?
            if (payload.id)
                return OtherIncomeCategoriesService.put(payload.id, payload);
            return OtherIncomeCategoriesService.post(null, payload);
        },
        /*
         * Action Blog
         * */
        actionIncomeCategorie({ commit }, payload) {
            return OtherIncomeCategoriesService.post('action', payload);
        },
        /*
         * Delete Blog
         * */
        deleteIncomeCategorie({ commit }, payload) {
            return OtherIncomeCategoriesService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default OtherIncomeCategoriesStore;